import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SEO from "@shared/seo";
import { Query } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import styled from "styled-components";
import { BlocksContent } from "@global";

const Wrapper = styled(Container)`
  background-color: ${colors.darkBlue};
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-top: 110px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 0;
  }
`;

const ContentContainer = styled(Container)`
  align-items: center;
  flex-direction: row;
  text-align: left;
  width: 50%;
  margin: 0 auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

const PrivacyPolicyPage = () => {
  const { sanityPrivacyPolicyPage }: Query = useStaticQuery(graphql`
    {
      sanityPrivacyPolicyPage {
        textBlock {
          _rawColumnContent
        }
        seo {
          ...sanitySeo
        }
      }
    }
  `);

  if (sanityPrivacyPolicyPage == null) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityPrivacyPolicyPage?.seo} />
      <Wrapper>
        <ContentContainer>
          <BlocksContent
            blocks={sanityPrivacyPolicyPage?.textBlock?._rawColumnContent}
          />
        </ContentContainer>
      </Wrapper>
    </>
  );
};

export default PrivacyPolicyPage;
